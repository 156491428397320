
import { defineComponent } from 'vue';
import AppHeader from './components/AppHeader.vue'
import AppContacts from './components/AppContacts.vue'
import AppSelectType from './components/AppSelectType.vue'
import AppSelectHead from './components/AppSelectHead.vue'
import AppComments from './components/AppComments.vue'
import AppLoader from './components/AppLoader.vue'
import axios from 'axios'


export default defineComponent({
  name: 'App',
  components: {
    AppHeader,
    AppContacts,
    AppSelectType,
    AppSelectHead,
    AppComments,
    AppLoader,

  },
  data() {
    return {
      name: '',
      email: '',
      selectedtype: '1',
      selectedhead: '1',
      comment: '',
      file: '',
      loaderNotActive: true,
      // isDisabled: true,
    }
  },
  methods: {
    setContacts(name: string) {
      this.name = name
    },

    setEmail(email: string) {
      this.email = email
    },

    setComment(coment: string) {
      this.comment = coment
    },

    changeType(selected: string) {
      this.selectedtype = selected
    },

    changeHead(selected: string) {
      this.selectedhead = selected
    },
    
    changeLoader(){
      this.loaderNotActive = !this.loaderNotActive;
    },
    submitForm() {
      this.changeLoader();
      let formData = new FormData();
      for (let i = 0; i < this.file.length; i++) {
        formData.append('file[' + i + ']', this.file[i])
      }
      // formData.append('file', this.file);
      formData.append('title', this.titleComputed)
      formData.append('descript', this.descriptionWithFrom())
      formData.append('auditors', this.auditors)
      formData.append('responsibleId', this.responsebleId)
      console.log('>> formData >> ', formData);
      if (this.name && this.email && this.comment) {
        axios.post('./backend/PHP/task_add/form.php',
          formData, {
          headers: { 'Content-Type': 'multipart/form-data' }
        })
          .then( () => {
            console.log('SUCCESS!!')
            alert('Всё было отослоно ^_^')
            this.changeLoader()
          })
          .catch(function () { console.log('FAILURE!!') })
      } else {
        console.log('Заполните поля')
      }
    },

    handleFileChange(event: any) {
      this.file = event.target.files
      console.log('>>>>>>>>>>>>>>', this.file)
    },

    descriptionWithFrom(): string {
      return ('Заявка от ' + this.name +
        '.\n' + 'E-mail: ' + this.email +
        '\n===================================\n' + this.comment)
    },

  },
  computed: {
    titleComputed: function () {
      if (this.selectedtype == '1' && this.selectedhead == '1') {
        return '1C:Предприятие - Программирование'
      } else if (this.selectedtype == '1' && this.selectedhead == '2') {
        return '1C:Предприятие - Ошибка'
      } else if (this.selectedtype == '1' && this.selectedhead == '3') {
        return '1C:Предприятие - Проблема'
      } else if (this.selectedtype == '2') {
        return 'Техническая проблема'
      } else {
        return 'Другое'
      }

    },

    responsebleId: function () {
      if (this.selectedtype == '1') {
        return '15';
      } else if (this.selectedtype == '2') {
        return '103'
      } else {
        return '103'
      }
    },

    auditors: function () {
      return '1'
    },
    isDisabled: function () {
      if (this.name && this.email && this.comment && this.loaderNotActive) {
        return false
      } else {
        return true
      }
    },
  }
});
