
import { defineComponent } from 'vue';

interface State {
    coment: string
}

export default defineComponent({
    data(): State {
        return {
            coment: '',
        }
    }
})
