
import { defineComponent } from 'vue';

export default defineComponent({
    data() {
        return {
            selected: '1',
        }
    },
    methods: {
        changeHead(event: Event) {
            const key = (event.target as HTMLSelectElement).value
            this.selected = key
            this.$emit('changeHead', this.selected)
            // console.log(this.selected)
        }
    }
})
