
import { defineComponent } from 'vue';

interface State {
    name: string,
    email: string,
}

export default defineComponent({
    data(): State {
        return {
            name: '',
            email: '',
        }
    }
})
