
import { defineComponent } from 'vue';

export default defineComponent({
    data() {
        return {
            selected: '1',
        }
    },
    methods: {
        changeType: function (event: Event) {
            const key = (event.target as HTMLInputElement).value
            this.selected = key
            // console.log(key)
            this.$emit('changeType', this.selected)
        },
    },
})
